import ApolloClient from 'apollo-client';
import { useMemo } from 'react';
import { getVXServiceNotificationsClient, useApolloClient } from '../index';
import { ClientsNamespace } from '../types';

export const useCreateVXServicesNotificationsClient = (
  notificationsToken: string | undefined
): ApolloClient<unknown> =>
  useMemo(
    () => getVXServiceNotificationsClient({ token: notificationsToken }),
    [notificationsToken]
  );

export const useVXServicesNotificationsClient = (): ApolloClient<Record<string, unknown>> =>
  useApolloClient(ClientsNamespace.VXServicesNotifications);
