import styled from '@emotion/styled';
import React, { FC } from 'react';
import { RED, WHITE } from '../../../camtool-styles';

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 4px;
  height: 19px;
  width: 19px;
  font-size: 10px;
  color: ${WHITE};
  background: ${RED};
  border-radius: 50%;
`;

type IconBellWithBadgeProps = {
  count: number;
};

export const IconBellWithBadge: FC<IconBellWithBadgeProps> = ({ count }) => {
  return (
    <div>
      <span className="icon-bell" style={{ fontSize: 16 }} />
      {count > 0 && <Badge>{count > 99 ? '+99' : count}</Badge>}
    </div>
  );
};
