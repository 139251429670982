import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import React, { FC, useEffect, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import { BLACK, WHITE } from '../../../camtool-styles';
import { useVXServicesNotificationsClient } from '../../../graphql';
import { NotificationsFilterPresetEnum } from '../../../graphql/VXServicesNotifications/types';
import { useLang } from '../../../util/AppState';
import {
  NOTIFICATIONS_LIMIT,
  updateQuery,
  updateQueryAfterMarkRead,
  VXMN_MUTATION_NOTIFICATIONS_MARKREAD,
  VXMN_QUERY_COUNT_NOTIFICATIONS,
  VXMN_QUERY_NOTIFICATIONS,
} from '../../../util/NotificationsHelper';
import { _ } from '../../../util/translate';
import { EmptyContent, Spinner } from '../../index';
import Translation from '../../Translation/Translation';
import { BREAKPOINT_NOTIFICATIONS_POPUP_OFFSET, TOP_BAR_BUTTON_WIDTH } from '../constants';
import { StyledButton } from '../styles';
import { IconBellWithBadge } from './IconBellWithBadge';

import TopBarNotificationsEntry from './TopBarNotificationsEntry';
import TopBarNotificationsOverlay from './TopBarNotificationsOverlay';

const Container = styled.div`
  position: relative;
`;

const PopupContainer = styled.div`
  position: absolute;
  right: 0;
  top: 49px;
  flex-direction: column;
  align-items: center;
  background-color: ${WHITE};
  color: ${BLACK};
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  min-width: 300px;
  height: 300px;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINT_NOTIFICATIONS_POPUP_OFFSET}px) {
    right: -${TOP_BAR_BUTTON_WIDTH * 2}px;
  }
`;
const TopBarNotifications: FC = () => {
  const [lang] = useLang();
  const containerRef = useRef<HTMLElement | null>(null);

  // handling the popup
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
    setShowNotification(null);
  };

  const [showNotification, setShowNotification] = useState(null);

  // handling the popup
  const buttonRef = useRef<HTMLElement | null>(null);

  const handlePageClick = (ev: UIEvent) => {
    // If the button is clicked, we let the button handle stuff. Otherwise, we handle it.
    if (buttonRef.current && buttonRef.current.contains(ev.target)) {
      return null;
    } else {
      // !!containerRef.current means whether isOpen is true or false. If it
      // is open but the user clicked outside it, then we set isOpen to false.
      // otherwise we do nothing
      if (containerRef.current && !containerRef.current.contains(ev.target)) {
        close();
      }
    }
  };
  useEffect(() => {
    // register click listener for closing the popup outside of it
    window.addEventListener('click', handlePageClick);
    return () => {
      window.removeEventListener('click', handlePageClick);
    };
  }, []);

  // GraphQL stuff
  const client = useVXServicesNotificationsClient();
  const { data: countData } = useQuery(VXMN_QUERY_COUNT_NOTIFICATIONS, {
    client,
    variables: { lang, filter: { preset: NotificationsFilterPresetEnum.INFO_UNREAD } },
  });

  const buildVariables = (cursor = '') => ({
    lang,
    filter: { preset: NotificationsFilterPresetEnum.INFO_ALL, sort: 'updated_at_desc' },
    count: NOTIFICATIONS_LIMIT,
    after: cursor,
  });

  const { loading, error, data, fetchMore } = useQuery(VXMN_QUERY_NOTIFICATIONS, {
    client,
    variables: buildVariables(),
  });

  const [markRead] = useMutation(VXMN_MUTATION_NOTIFICATIONS_MARKREAD, {
    client,
    update: updateQueryAfterMarkRead(
      'vxmnQueryNotifications',
      VXMN_QUERY_NOTIFICATIONS,
      buildVariables('')
    ),
    refetchQueries: ['GetCountNotifications'],
  });

  const edges = data?.vxmnQueryNotifications?.pagination?.edges ?? [];
  const items = edges.map((edge) => edge.node) ?? [];
  const count = countData?.vxmnQueryNotificationsCount?.pagination?.totalCount ?? 0;
  const hasNextPage = data?.vxmnQueryNotifications?.pagination?.pageInfo?.hasNextPage ?? true;
  const cursor = edges[edges.length - 1]?.cursor ?? '';

  return (
    <Container>
      <StyledButton ref={buttonRef} onClick={isOpen ? close : open} isOpen={isOpen}>
        <IconBellWithBadge count={count} />
      </StyledButton>

      {isOpen && (
        <PopupContainer ref={containerRef}>
          {showNotification && (
            <TopBarNotificationsOverlay
              {...showNotification}
              onClickBack={(e) => {
                setShowNotification(null);
                e.stopPropagation();
              }}
              onLinkClick={close}
            />
          )}
          <InfiniteScroll
            className="grid__box__item__content"
            style={{ width: '100%', overflow: showNotification ? 'hidden' : 'unset' }}
            initialLoad={false}
            loadMore={() => {
              if (!loading) {
                fetchMore({
                  variables: buildVariables(cursor),
                  updateQuery: updateQuery('vxmnQueryNotifications'),
                });
              }
            }}
            hasMore={hasNextPage}
            loader={<Spinner key="loading" size="xs" inline={true} />}
            useWindow={false}
          >
            {error && (
              <EmptyContent
                style={{ div: { fontSize: '16px !important' } }}
                icon="icon-bell"
                title={<Translation t={'dashboard:app.notification.emptyContent.title'} />}
              />
            )}
            {items.map((item) => (
              <TopBarNotificationsEntry
                className="topbar__notification__entry"
                key={item.id}
                {...item}
                onMarkRead={item.readAt === null ? markRead : null}
                openNotification={() => setShowNotification(item)}
                onLinkClick={close}
              />
            ))}

            {!error && items.length === 0 && (
              <EmptyContent
                icon="icon-bell"
                title={_('dashboard:app.notification.emptyContent.title')}
              />
            )}
          </InfiniteScroll>
        </PopupContainer>
      )}
    </Container>
  );
};

export default TopBarNotifications;
