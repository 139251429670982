import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** RFC 3986 compliant URI string. See https://www.rfc-editor.org/rfc/rfc3986 */
  Uri: any;
  /** Like `srcset` of a HTML `img`, see https://html.spec.whatwg.org/multipage/embedded-content.html#attr-img-srcset */
  SrcSet: any;
  /** Returns JSON representation in ISO 8601 (used by JavaScript) */
  DateTime: any;
  /** array of key => value */
  Dict: any;
};

export type ApiInfo = {
  __typename?: 'ApiInfo';
  cbaseEnv: Scalars['String'];
  environment: Scalars['String'];
  isDebug: Scalars['Boolean'];
  phpVersion: Scalars['String'];
  vxfeedApiKeyHash?: Maybe<Scalars['String']>;
  host: Scalars['String'];
  version: Scalars['String'];
  gitCommitHash: Scalars['String'];
};

/** Available API languages */
export enum ApiLangEnum {
  /** German */
  de = 'de',
  /** Englisch */
  en = 'en'
}

export type AuthInfo = {
  __typename?: 'AuthInfo';
  user?: Maybe<AuthUser>;
};

export type AuthUser = {
  __typename?: 'AuthUser';
  userId: Scalars['Int'];
  username: Scalars['String'];
  roles: Array<Scalars['String']>;
  lang: ApiLangEnum;
  avatar?: Maybe<Image>;
};

export type Button = {
  __typename?: 'Button';
  label: Scalars['String'];
  action: Scalars['Uri'];
};



/** like HTML `img`, see https://html.spec.whatwg.org/multipage/embedded-content.html#the-img-element */
export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['Uri']>;
  srcset?: Maybe<Scalars['SrcSet']>;
  sizes?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
};

export enum ImageSizeEnum {
  /** Original size */
  orig = 'orig',
  /** max. 40px box (any value) */
  b40 = 'b40',
  /** max. 60px box (any value) */
  b60 = 'b60',
  /** max. 140px box (any value) */
  b140 = 'b140',
  /** max. 160px box (any value) */
  b160 = 'b160',
  /** max. 320px box (any value) */
  b320 = 'b320',
  /** max. 640px box (any value) */
  b640 = 'b640',
  /** max. 1024px box (any value) */
  b1024 = 'b1024'
}

export type Link = {
  __typename?: 'Link';
  label: Scalars['String'];
  uri: Scalars['Uri'];
};

export type Notification = NotificationInterface & {
  __typename?: 'Notification';
  id: Scalars['ID'];
  userId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<NotificationGroupEnum>;
  category: Scalars['String'];
  priority: NotificationPriorityEnum;
  type: NotificationTypeEnum;
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  readAt?: Maybe<Scalars['DateTime']>;
  showFrom: Scalars['DateTime'];
  showUntil: Scalars['DateTime'];
  subtitle?: Maybe<Scalars['String']>;
  shortInfo?: Maybe<Scalars['String']>;
  picture?: Maybe<Image>;
  icon?: Maybe<Image>;
  primaryButton?: Maybe<Button>;
  secondaryButton?: Maybe<Button>;
  link?: Maybe<Link>;
  uri?: Maybe<Scalars['Uri']>;
  params?: Maybe<Scalars['Dict']>;
};


export type NotificationpictureArgs = {
  size?: Maybe<ImageSizeEnum>;
  sizes?: Array<ImageSizeEnum>;
};

/** A connection to a list of items. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  totalCount?: Maybe<Scalars['Int']>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>;
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** The item at the end of the edge. */
  node?: Maybe<NotificationInterface>;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** NotificationGroupEnum */
export enum NotificationGroupEnum {
  /** All models, not restricted, last login < 90d */
  ALL_MODELS_NOT_RESTRICTED_LAST_LOGIN_90D = 'ALL_MODELS_NOT_RESTRICTED_LAST_LOGIN_90D',
  /** All models not company without TIN */
  ALL_MODELS_WITHOUT_TIN = 'ALL_MODELS_WITHOUT_TIN',
  /** VXModels Developer and Testers */
  DEVELOPER_VXMODELS = 'DEVELOPER_VXMODELS',
  /** Models, displayed on VISIT-X, sending allowed, last login < 90d */
  VISITX_FEMALE_MODELS_SENDING_ALLOWED_LAST_LOGIN_90D = 'VISITX_FEMALE_MODELS_SENDING_ALLOWED_LAST_LOGIN_90D',
  /** Models, displayed on VISIT-X, sending allowed, last login < 90d */
  VISITX_MODELS_SENDING_ALLOWED_LAST_LOGIN_90D = 'VISITX_MODELS_SENDING_ALLOWED_LAST_LOGIN_90D',
  /** Models, displayed on VISIT-X in VIP30 next month */
  VISITX_MODELS_WITH_VIP30_VIDEOS_NEXT_MONTH = 'VISITX_MODELS_WITH_VIP30_VIDEOS_NEXT_MONTH',
  /** Models, displayed on VISIT-X in VIP30 this month */
  VISITX_MODELS_WITH_VIP30_VIDEOS_THIS_MONTH = 'VISITX_MODELS_WITH_VIP30_VIDEOS_THIS_MONTH',
  /** Models with rejected actor documents */
  VXMODELS_USERS_WITH_REJECTED_ACTOR_DOCUMENTS = 'VXMODELS_USERS_WITH_REJECTED_ACTOR_DOCUMENTS'
}

export type NotificationInterface = {
  id: Scalars['ID'];
  userId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<NotificationGroupEnum>;
  type: NotificationTypeEnum;
  category: Scalars['String'];
  priority: NotificationPriorityEnum;
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  readAt?: Maybe<Scalars['DateTime']>;
  showFrom: Scalars['DateTime'];
  showUntil: Scalars['DateTime'];
  params?: Maybe<Scalars['Dict']>;
};

/** Priority of notification */
export enum NotificationPriorityEnum {
  /** Highest priority */
  HIGHEST = 'HIGHEST',
  /** Higher priority */
  HIGHER = 'HIGHER',
  /** High priority */
  HIGH = 'HIGH',
  /** Normal priority */
  NORMAL = 'NORMAL',
  /** Low priority */
  LOW = 'LOW',
  /** Lower priority */
  LOWER = 'LOWER',
  /** Lowest priority */
  LOWEST = 'LOWEST'
}

export type Notifications = {
  __typename?: 'Notifications';
  /** @deprecated Use . > filter > userId instead. */
  userId?: Maybe<Scalars['ID']>;
  /** Filter for notifications */
  filter?: Maybe<NotificationsFilter>;
  /** @deprecated About to remove due to performance reasons here. Use query > types instead. */
  types: Array<NotificationsType>;
  pagination?: Maybe<NotificationConnection>;
};


export type NotificationspaginationArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};

export type NotificationsFilter = {
  __typename?: 'NotificationsFilter';
  /** Notifications by userId */
  userId?: Maybe<Scalars['Int']>;
  /** Filter preset notifications */
  preset?: Maybe<NotificationsFilterPresetEnum>;
  /** Sort order for notifications */
  sort?: Maybe<NotificationsSortEnum>;
};

export type NotificationsFilterInput = {
  /** Filter notifications */
  preset?: Maybe<NotificationsFilterPresetEnum>;
  /** Sort order for notifications */
  sort?: Maybe<NotificationsSortEnum>;
};

/** NotificationsFilterPresetEnum */
export enum NotificationsFilterPresetEnum {
  /**
   * All notifications
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   */
  ALL = 'ALL',
  /**
   * All unread notifications
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   */
  ALL_UNREAD = 'ALL_UNREAD',
  /**
   * Info notifications only
   *  - Priority:
   *    - min: `LOWEST`
   *    - max: `NORMAL`
   *  - Types:
   *    - include: `.+`
   *    - exclude: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   */
  INFO_ALL = 'INFO_ALL',
  /**
   * Unread info notifications only
   *  - Priority:
   *    - min: `LOWEST`
   *    - max: `NORMAL`
   *  - Types:
   *    - include: `.+`
   *    - exclude: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   */
  INFO_UNREAD = 'INFO_UNREAD',
  /**
   * Important notifications only
   *  - Priority:
   *    - min: `HIGH`
   *    - max: `HIGHEST`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   */
  IMPORTANT_ALL = 'IMPORTANT_ALL',
  /**
   * Unread important notifications only
   *  - Priority:
   *    - min: `HIGH`
   *    - max: `HIGHEST`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   */
  IMPORTANT_UNREAD = 'IMPORTANT_UNREAD',
  /**
   * All news center notifications
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `null`
   *  - Unread only: `false`
   */
  NEWS_CENTER = 'NEWS_CENTER',
  /**
   * All news center notifications
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   */
  NEWS_CENTER_ALL = 'NEWS_CENTER_ALL',
  /**
   * Unread news center notifications
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   */
  NEWS_CENTER_UNREAD = 'NEWS_CENTER_UNREAD',
  /**
   * Snag bar notifications
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   */
  SNAG_BAR = 'SNAG_BAR',
  /**
   * All announcement notifications (manager view)
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `null`
   *  - Show until: `null`
   *  - Unread only: `false`
   */
  MANAGER_ANNOUNCEMENT_ALL = 'MANAGER_ANNOUNCEMENT_ALL',
  /**
   * All upcoming notifications (manager view)
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `null`
   *  - Unread only: `false`
   */
  MANAGER_ANNOUNCEMENT_UPCOMING = 'MANAGER_ANNOUNCEMENT_UPCOMING',
  /**
   * All past notifications (manager view)
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `null`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   */
  MANAGER_ANNOUNCEMENT_PAST = 'MANAGER_ANNOUNCEMENT_PAST'
}

export type NotificationsMutation = {
  __typename?: 'NotificationsMutation';
  markRead?: Maybe<NotificationInterface>;
};


export type NotificationsMutationmarkReadArgs = {
  id: Scalars['ID'];
};

/** Sort order for notifications */
export enum NotificationsSortEnum {
  /** Updated at descending */
  updated_at_desc = 'updated_at_desc'
}

export type NotificationsType = {
  __typename?: 'NotificationsType';
  id: NotificationTypeEnum;
  category: Scalars['String'];
  count: Scalars['Int'];
};

/** NotificationTypeEnum */
export enum NotificationTypeEnum {
  /** Notification for a contest */
  ANNOUNCEMENT_CONTEST = 'ANNOUNCEMENT_CONTEST',
  /** Direct Message with free text, to a user or a group of users */
  ANNOUNCEMENT_DIRECT_MESSAGE = 'ANNOUNCEMENT_DIRECT_MESSAGE',
  /** Higher priority announcement */
  ANNOUNCEMENT_HIGHER_PRIORITY = 'ANNOUNCEMENT_HIGHER_PRIORITY',
  /** Notification for marketing purposes */
  ANNOUNCEMENT_MARKETING = 'ANNOUNCEMENT_MARKETING',
  /** Custom free text notification */
  ANNOUNCEMENT_SYSTEM_MESSAGE = 'ANNOUNCEMENT_SYSTEM_MESSAGE',
  /** Notification for TV purposes */
  ANNOUNCEMENT_TELEVISION = 'ANNOUNCEMENT_TELEVISION',
  /** Notification for VXGames winners */
  ANNOUNCEMENT_VXGAMES = 'ANNOUNCEMENT_VXGAMES',
  /** Notification for VXGames winners */
  ANNOUNCEMENT_VXGAMES_WINNER = 'ANNOUNCEMENT_VXGAMES_WINNER',
  /** The payout was locked */
  HOST_PAYOUT_LOCKED = 'HOST_PAYOUT_LOCKED',
  /** The payout was approved */
  HOST_PAYOUT_UNLOCKED = 'HOST_PAYOUT_UNLOCKED',
  /** The avatar picture was accepted by support */
  MEDIA_PICTURE_AVATAR_ACCEPTED = 'MEDIA_PICTURE_AVATAR_ACCEPTED',
  /** The avatar picture was rejected by support */
  MEDIA_PICTURE_AVATAR_REJECTED = 'MEDIA_PICTURE_AVATAR_REJECTED',
  /** A sedcard picture was accepted by support */
  MEDIA_PICTURE_SEDCARD_ACCEPTED = 'MEDIA_PICTURE_SEDCARD_ACCEPTED',
  /** A sedcard picture was rejected by support */
  MEDIA_PICTURE_SEDCARD_REJECTED = 'MEDIA_PICTURE_SEDCARD_REJECTED',
  /** The title picture was accepted by support */
  MEDIA_PICTURE_TITLE_ACCEPTED = 'MEDIA_PICTURE_TITLE_ACCEPTED',
  /** The title picture was rejected by support */
  MEDIA_PICTURE_TITLE_REJECTED = 'MEDIA_PICTURE_TITLE_REJECTED',
  /** Shop video bought by guest */
  MEDIA_VIDEO_SHOP_TRANSACTION = 'MEDIA_VIDEO_SHOP_TRANSACTION',
  /** Video is selected as VIP30 next month */
  MEDIA_VIDEO_VIP30_NEXT_MONTH = 'MEDIA_VIDEO_VIP30_NEXT_MONTH',
  /** Video is selected as VIP30 this month */
  MEDIA_VIDEO_VIP30_THIS_MONTH = 'MEDIA_VIDEO_VIP30_THIS_MONTH',
  /** Documents of a linked actor were rejected */
  PAYMENT_ACTOR_DOCUMENTS_REJECTED = 'PAYMENT_ACTOR_DOCUMENTS_REJECTED',
  /** Payment address data accepted */
  PAYMENT_ADDRESS_DATA_ACCEPTED = 'PAYMENT_ADDRESS_DATA_ACCEPTED',
  /** Address data rejected */
  PAYMENT_ADDRESS_DATA_REJECTED = 'PAYMENT_ADDRESS_DATA_REJECTED',
  /** Address Proof accepted */
  PAYMENT_ADDRESS_PROOF_ACCEPTED = 'PAYMENT_ADDRESS_PROOF_ACCEPTED',
  /** Address proof missing */
  PAYMENT_ADDRESS_PROOF_MISSING = 'PAYMENT_ADDRESS_PROOF_MISSING',
  /** Address Proof rejected */
  PAYMENT_ADDRESS_PROOF_REJECTED = 'PAYMENT_ADDRESS_PROOF_REJECTED',
  /** Address Proof accepted */
  PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_ACCEPTED = 'PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_ACCEPTED',
  /** Address proof missing */
  PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_MISSING = 'PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_MISSING',
  /** Address Proof rejected */
  PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_REJECTED = 'PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_REJECTED',
  /** Business registration accepted */
  PAYMENT_HOST_DOCUMENT_BUSINESS_REG_ACCEPTED = 'PAYMENT_HOST_DOCUMENT_BUSINESS_REG_ACCEPTED',
  /** Business registration missing */
  PAYMENT_HOST_DOCUMENT_BUSINESS_REG_MISSING = 'PAYMENT_HOST_DOCUMENT_BUSINESS_REG_MISSING',
  /** Business registration rejected */
  PAYMENT_HOST_DOCUMENT_BUSINESS_REG_REJECTED = 'PAYMENT_HOST_DOCUMENT_BUSINESS_REG_REJECTED',
  /** Identity Proof accepted */
  PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_ACCEPTED = 'PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_ACCEPTED',
  /** Identity proof missing */
  PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_MISSING = 'PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_MISSING',
  /** Identity Proof rejected */
  PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_REJECTED = 'PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_REJECTED',
  /** ID Shot accepted */
  PAYMENT_HOST_DOCUMENT_ID_SHOT_ACCEPTED = 'PAYMENT_HOST_DOCUMENT_ID_SHOT_ACCEPTED',
  /** ID Shot missing */
  PAYMENT_HOST_DOCUMENT_ID_SHOT_MISSING = 'PAYMENT_HOST_DOCUMENT_ID_SHOT_MISSING',
  /** ID Shot rejected */
  PAYMENT_HOST_DOCUMENT_ID_SHOT_REJECTED = 'PAYMENT_HOST_DOCUMENT_ID_SHOT_REJECTED',
  /** Model Release Form accepted */
  PAYMENT_HOST_DOCUMENT_MRF_ACCEPTED = 'PAYMENT_HOST_DOCUMENT_MRF_ACCEPTED',
  /** VAT document missing */
  PAYMENT_HOST_DOCUMENT_MRF_MISSING = 'PAYMENT_HOST_DOCUMENT_MRF_MISSING',
  /** Model Release Form rejected */
  PAYMENT_HOST_DOCUMENT_MRF_REJECTED = 'PAYMENT_HOST_DOCUMENT_MRF_REJECTED',
  /** VAT Document accepted */
  PAYMENT_HOST_DOCUMENT_VAT_ACCEPTED = 'PAYMENT_HOST_DOCUMENT_VAT_ACCEPTED',
  /** VAT document missing */
  PAYMENT_HOST_DOCUMENT_VAT_MISSING = 'PAYMENT_HOST_DOCUMENT_VAT_MISSING',
  /** VAT Document rejected */
  PAYMENT_HOST_DOCUMENT_VAT_REJECTED = 'PAYMENT_HOST_DOCUMENT_VAT_REJECTED',
  /** Payout data accepted */
  PAYMENT_PAYOUT_DATA_ACCEPTED = 'PAYMENT_PAYOUT_DATA_ACCEPTED',
  /** Payout data rejected */
  PAYMENT_PAYOUT_DATA_REJECTED = 'PAYMENT_PAYOUT_DATA_REJECTED',
  /** Tax data accepted */
  PAYMENT_TAX_DATA_ACCEPTED = 'PAYMENT_TAX_DATA_ACCEPTED',
  /** Tax data will expire soon */
  PAYMENT_TAX_DATA_EXPIRE_SOON = 'PAYMENT_TAX_DATA_EXPIRE_SOON',
  /** Tax data rejected */
  PAYMENT_TAX_DATA_REJECTED = 'PAYMENT_TAX_DATA_REJECTED',
  /** Taxpayer Identification Number (TIN) is missing */
  PAYMENT_TAX_DATA_TIN_MISSING = 'PAYMENT_TAX_DATA_TIN_MISSING',
  /** A guest added the model to his favorites */
  VXFEED_FAVORITE_ADDED = 'VXFEED_FAVORITE_ADDED',
  /** A featured post was published */
  VXFEED_FEATURED_POST_PUBLISHED = 'VXFEED_FEATURED_POST_PUBLISHED',
  /** A guest commented on a post */
  VXFEED_POST_COMMENTED = 'VXFEED_POST_COMMENTED',
  /** A guest replied to a comment */
  VXFEED_POST_COMMENT_REPLIED = 'VXFEED_POST_COMMENT_REPLIED',
  /** A guest liked a post */
  VXFEED_POST_LIKED = 'VXFEED_POST_LIKED',
  /** A post was rated FSK 18+ */
  VXFEED_POST_RATED_FSK18 = 'VXFEED_POST_RATED_FSK18',
  /** A post was rejected */
  VXFEED_POST_REJECTED = 'VXFEED_POST_REJECTED',
  /** A feed post has been successfully scheduled */
  VXFEED_POST_SCHEDULED = 'VXFEED_POST_SCHEDULED',
  /** A customer bought feed post content */
  VXFEED_POST_SOLD = 'VXFEED_POST_SOLD',
  /** A guest tipped a post */
  VXFEED_POST_TIPPED = 'VXFEED_POST_TIPPED',
  /** A feed post Tipping Goal was reached */
  VXFEED_POST_TIPPING_GOAL_ACHIEVED = 'VXFEED_POST_TIPPING_GOAL_ACHIEVED'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type RootApiMutation = {
  __typename?: 'RootApiMutation';
  notifications?: Maybe<NotificationsMutation>;
};

export type RootApiQuery = {
  __typename?: 'RootApiQuery';
  auth?: Maybe<AuthInfo>;
  api?: Maybe<ApiInfo>;
  notifications?: Maybe<Notifications>;
};


export type RootApiQuerynotificationsArgs = {
  lang: ApiLangEnum;
  filter?: Maybe<NotificationsFilterInput>;
};


