import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import GenericEmptyContent from '../../atoms/GenericEmptyContent/GenericEmptyContent';
import { BLACK_2, BREAKPOINT_PHONE_CONDITION } from '../../camtool-styles';
import { Markdown, NotificationBox, Spinner } from '../../components';
import { QUERY_WELCOME_CONTENT } from '../../graphql/VXModels/queries';
import { SecurityRole } from '../../graphql/VXModels/types';
import { useAppState } from '../../util/AppState';
import { _ } from '../../util/translate';
import { useHasRole } from '../../util/UserData';
import AccountSwitchToBeta from '../Account/AccountSwitchToBeta';
import { BREAKPOINT } from './constants';

interface Props {
  onDocsUploadedSuccess: () => void;
  onProfilePicUploadSuccess: () => void;
}

const Container = styled.div`
  flex-direction: column;
  align-items: center;
`;

const TileRow = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: ${BREAKPOINT}px) {
    flex-direction: row;
  }
`;

const Headline = styled.h3`
  text-align: center;
  color: ${BLACK_2};
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;

  @media ${BREAKPOINT_PHONE_CONDITION} {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

const SpinnerContainer = styled.div`
  min-height: 300px;
  width: 100%;
  position: relative;
`;

const WelcomeContent: FC<Props> = ({ onDocsUploadedSuccess, onProfilePicUploadSuccess }) => {
  const hasRoleAdmin = useHasRole(SecurityRole.VX_USER_ADMIN);
  const [{ lang }] = useAppState();

  const {
    loading: welcomeLoading,
    error: welcomeError,
    data: welcomeData,
  } = useQuery(QUERY_WELCOME_CONTENT, {
    fetchPolicy: 'cache-and-network',
  });

  const preview = welcomeData?.model?.media.previewPicture16;
  const picture = preview?.picture;
  const isPictureAccepted = picture?.isChecked;

  const isActorDocsUploaded = welcomeData?.model?.documents?.allActorDocumentsUploaded;
  const isOriginatorDocsUploaded = welcomeData?.model?.documents?.allOriginatorDocumentsUploaded;

  const hasDocsUploaded = isActorDocsUploaded || isOriginatorDocsUploaded;

  if (isPictureAccepted && hasDocsUploaded) {
    onProfilePicUploadSuccess();
    onDocsUploadedSuccess();
  }

  if (welcomeLoading) {
    return (
      <SpinnerContainer>
        <Spinner noBackground={true} />
      </SpinnerContainer>
    );
  }

  if (welcomeError) {
    return <GenericEmptyContent />;
  }

  return (
    <Container>
      <Headline>
        {lang !== 'en'
          ? 'Lade deine Dokumente schnell und einfach auf unserer neuen Oberfläche hoch und starte durch!'
          : 'Upload your documents quickly and easily to our new interface and get started through!'}
        <Markdown linkTarget={'_blank'} source={_('welcome:assistent.verifyOutlook')} />
      </Headline>
      <TileRow>
        <AccountSwitchToBeta isStartpage={true} />
      </TileRow>
      {!hasRoleAdmin && <NotificationBox />}
    </Container>
  );
};

export default WelcomeContent;
