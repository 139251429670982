import React, { FC } from 'react';
import { FormatDate } from '../../../components/Formatter';

import Markdown from '../../../components/Markdown/Markdown';
import {
  getIconAndColor,
  MappedBetaLink,
  optimisticMarkReadResponse,
} from '../../../util/NotificationsHelper';

type TopBarNotificationsEntryProps = {
  id: string;
  updatedAt: string;
  title: string;
  shortInfo: string;
  onMarkRead?: any;
  icon?: any;
  picture?: any;
  openNotification?: any;
  onLinkClick?: any;
  uri: string;
};

const TopBarNotificationsEntry: FC<TopBarNotificationsEntryProps> = ({
  id,
  updatedAt,
  title,
  shortInfo,
  onMarkRead,
  icon,
  picture,
  openNotification,
  onLinkClick,
  uri,
}) => {
  const { iconType, color: iconColor } = getIconAndColor(icon?.src);

  const wrappedOpenNotification = () => {
    if (onMarkRead) {
      onMarkRead({
        variables: { id },
        optimisticResponse: optimisticMarkReadResponse(id),
      });
    }
    openNotification();
  };
  const wrappedOnLinkClick = () => {
    if (onMarkRead) {
      onMarkRead({
        variables: { id },
        optimisticResponse: optimisticMarkReadResponse(id),
      });
    }
    onLinkClick;
  };

  return (
    <MappedBetaLink
      link={uri}
      openNotification={wrappedOpenNotification}
      onLinkClick={wrappedOnLinkClick}
      style={{ cursor: 'pointer', textDecoration: 'none', color: 'unset' }}
      className="topbar__notification__entry"
    >
      <div className="topbar__notification__entry__titledate">
        {iconType && iconColor && (
          <div
            className={iconType}
            style={{ color: iconColor, display: 'inline-block', marginRight: '5px' }}
          />
        )}
        <span className="--title">{shortInfo}</span>
        <FormatDate className="--date" value={updatedAt} />
        {onMarkRead && (
          <div
            className="topbar__notification__entry__remove"
            key={id}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onMarkRead({
                variables: { id },
                optimisticResponse: optimisticMarkReadResponse(id),
              });
            }}
          />
        )}
      </div>
      <div className="topbar__notification__entry__content">
        <div>
          <div className="topbar__notification__entry__content__textbox">
            <Markdown
              className="topbar__notification__entry__content__description"
              source={title}
            />
          </div>
          {picture && (
            <div
              className={'topbar__notification__entry__content__picture'}
              style={{ backgroundImage: 'url(' + picture.src + ')' ?? '' }}
            />
          )}
        </div>
      </div>
    </MappedBetaLink>
  );
};

// TopBarNotificationsEntry.propTypes = {
//   id: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
//   createdAt: PropTypes.string.isRequired,
//   updatedAt: PropTypes.string.isRequired,
//   readAt: PropTypes.string,
//   params: PropTypes.object,
//   link: PropTypes.object,
//   primaryButton: PropTypes.object,
//   secondaryButton: PropTypes.object,
//   title: PropTypes.string,
//   shortInfo: PropTypes.string,
//   onMarkRead: PropTypes.func,
//   icon: PropTypes.object,
//   picture: PropTypes.object,
//   openNotification: PropTypes.func,
//   onLinkClick: PropTypes.func,
//   uri: PropTypes.string,
// };
//
// TopBarNotificationsEntry.defaultProps = {
//   deletable: true,
//   title: '',
//   shortInfo: '',
//   createdAt: '',
//   updatedAt: '',
//   readAt: '',
//   params: {},
// };

export default TopBarNotificationsEntry;
