import React, { FC } from 'react';
import NewButton from '../../../atoms/Button/NewButton';
import { MappedBetaLink } from '../../../util/NotificationsHelper';

import Markdown from '../../Markdown/Markdown';
import Translation from '../../Translation/Translation';
import TopBarNotificationsEntry from './TopBarNotificationsEntry';

export const getButtons = ({ primaryButton, secondaryButton, link, onLinkClick }) => {
  const buttons = [];
  if (primaryButton) {
    buttons.push(
      <MappedBetaLink
        key={1}
        link={primaryButton.action}
        onLinkClick={onLinkClick}
        style={{ color: 'white', textDecoration: 'none', width: '100%' }}
      >
        <NewButton
          style={{
            height: 'unset',
            width: '100%',
            padding: '0.5rem 1rem',
            marginBottom: '0.5rem',
            fontSize: '1rem',
          }}
        >
          {primaryButton.label}
        </NewButton>
      </MappedBetaLink>
    );
  }
  if (secondaryButton) {
    buttons.push(
      <MappedBetaLink
        key={2}
        link={secondaryButton.action}
        style={{ color: 'white', textDecoration: 'none', width: '100%' }}
      >
        <NewButton
          variant={'outlined'}
          style={{
            height: 'unset',
            width: '100%',
            padding: '0.5rem 1rem',
            marginBottom: '0.5rem',
            fontSize: '1rem',
          }}
        >
          {secondaryButton.label}
        </NewButton>
      </MappedBetaLink>
    );
  }
  if (link) {
    buttons.push(
      <MappedBetaLink
        key={3}
        link={link.uri}
        style={{
          fontSize: '1rem',
          textAlign: 'center',
          height: 'unset',
          padding: '0.5rem 1rem',
          marginBottom: '0.5rem',
        }}
      >
        {link.label}
      </MappedBetaLink>
    );
  }
  return buttons;
};

type TopBarNotificationsOverlayProps = {
  primaryButton: {
    label: string;
    action: string;
  };
  secondaryButton: {
    label: string;
    action: string;
  };
  link: {
    uri: string;
    label: string;
  };
  onClickBack: () => void;
  onLinkClick: () => void;
  subtitle: string;
};
const TopBarNotificationsOverlay: FC<TopBarNotificationsOverlayProps> = (props) => {
  const { onClickBack, subtitle } = props;
  return (
    <div
      style={{
        backgroundColor: 'white',
        position: 'absolute',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 1,
      }}
    >
      <div style={{ flexDirection: 'column' }}>
        <NewButton
          variant={'text'}
          onClick={onClickBack}
          style={{
            width: '100%',
            justifyContent: 'left',
            paddingLeft: '10px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            fontSize: '1rem',
          }}
        >
          <span className="icon-chevron-left" style={{ marginRight: 5 }} />
          <Translation _={'common:button.back'} />
        </NewButton>
        <TopBarNotificationsEntry {...props} />
        {subtitle && (
          <span style={{ padding: '10px' }}>
            <Markdown>{subtitle}</Markdown>
          </span>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
        {getButtons(props)}
      </div>
    </div>
  );
};

export default TopBarNotificationsOverlay;
